export const getCookie = (name) => {
  var result = null;

  var cookieName = name + '=';
  var allcookies = document.cookie;

  var position = allcookies.indexOf( cookieName );
  if( position !== -1 )
  {
      var startIndex = position + cookieName.length;

      var endIndex = allcookies.indexOf( ';', startIndex );
      if( endIndex === -1 )
      {
          endIndex = allcookies.length;
      }

      result = decodeURIComponent(
          allcookies.substring( startIndex, endIndex ) );
  }

  return result;
}

export const setCookie = (authorization, accesstoken, maxAge = 21600) => {
  document.cookie = "Authorization=" + authorization + '; path=/; max-age=' + String(maxAge);
  document.cookie = "Accesstoken=" + accesstoken + '; path=/; max-age=' + String(maxAge);
}

export const setCookieGrade = (grade = '', maxAge = 21600) => {
  document.cookie = "grade=" + grade + '; path=/; max-age=' + String(maxAge);
}
