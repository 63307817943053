import React, {useEffect} from 'react';
import '../App.css';
import toptitle from '../images/toptitle.png';
import {useUserState} from '../hooks/useUserState';
import {openDocument} from '../utils/openDocument';
import {useLoadingState} from '../hooks/useLoadingState';
import {useNavigate} from 'react-router-dom';

const LoginPage = () => {
  const navigate = useNavigate();
  const {login, logout} = useUserState();

  const handleLogin = async () => {
    login()
  }

  const handlePasswordForgot = (event) => {
    navigate('/password_forgot')
  }

  const handleOpenDocument = (event) => {
    openDocument(event.currentTarget.id);
  }

  return (
    <div className="wrapper background-image-display">
      <div className='uk-width-1-1 uk-flex uk-flex-middle'>
        <div className='uk-container uk-animation-fade'>
          <img className='uk-align-center uk-width-small' src={toptitle} alt="title" width="300" />
          <button className="uk-button uk-button-primary uk-width-medium uk-align-center" type="button" onClick={handleLogin}>ログイン</button> 
          <button className="uk-button uk-hidden" id="modal-error" type="button" uk-toggle="target: #modal-error"></button>
          <hr />
          <button id="kiyaku" className="uk-button uk-button-text uk-margin-top uk-align-center" type="button" onClick={handleOpenDocument}>マモレポ利用規約</button>
        </div>
      </div>
      <div className='uk-card' style={{position: 'absolute', bottom: 0, right: 0, backgroundColor: "hotpink", width: "180px", textAlign: "center"}}>
        <a href="https://forms.gle/81MfvMbeoNVYHqu67" target="_blank" style={{color: 'white'}}>
          マモレポへのご意見
        </a>
      </div>
    </div>
  );
};

export default LoginPage;
