import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import {useUserState} from '../hooks/useUserState';
import {setCookie} from '../utils/cookie';

export const loginFormState = atom({
  key: 'loginFormState',
  default: {
    email: '',
    password: '',
    errorMsg: ''
  },
});

export const useLoginFormState = () => {
  const [loginForm, setLoginForm] = useRecoilState(loginFormState);
  const {setUserFromIdToken} = useUserState();

  const postOauth2Token = useCallback(
    async (code) => {
      const submitBody = {
        grant_type: 'authorization_code',
        client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
        scope: 'openid',
        redirect_uri: process.env.REACT_APP_APP_URL + "/loggedin",
        code: code,
      };

      const params = new URLSearchParams(submitBody);

      const url = process.env.REACT_APP_COGNITO_ENDPOINT + '/oauth2/token';
      const method = 'POST';
      const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
      const body = params;

      const res = await fetch(url, {method, headers, body});
      const data = await res.json();

      if (!res.ok) {
        return false;
      }

      setCookie(data['id_token'], data['access_token'])
      setUserFromIdToken(data['id_token']);

      return data;
    },
    []
  );

  return {
    loginForm: loginForm,
    setLoginForm: setLoginForm,
    postOauth2Token,
  };
};
