import { atom, useRecoilState } from 'recoil';

export const loadingState = atom({
  key: 'loadingState',
  default: false,
});

export const useLoadingState = () => {
  const [loading, setLoading] = useRecoilState(loadingState);

  return {
    loading: loading,
    setLoading: setLoading,
  };
};
