import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import {decodeJwt} from '../utils/jwt';
import {getCookie} from '../utils/cookie';

const counselorStatusStateDefault = {
  data: [],
}

export const counselorStatusState = atom({
  key: 'counselorStatusState',
  default: counselorStatusStateDefault,
});

export const useCounselorStatusState = () => {
  const [counselorStatus, setCounselorStatus] = useRecoilState(counselorStatusState);

  const fetchCounselorStatus = useCallback(
    async (userId) => {
      const url = process.env.REACT_APP_API_ENDPOINT + '/students/counselors/status';
      const method = 'GET';
      const headers = {
        'Authorization': getCookie('Authorization'),
        'accesstoken': getCookie('Accesstoken')
      };

      const res = await fetch(url, {method, headers});
      const data = await res.json();

      if (!res.ok) {
        return false;
      }

      setCounselorStatus({data: data['counselors']})

      return data;
    },
    []
  );

  return {
    counselorStatus: counselorStatus,
    setCounselorStatus: setCounselorStatus,
    fetchCounselorStatus: fetchCounselorStatus,
  };
};
