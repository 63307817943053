import React, {useEffect} from 'react';
import '../App.css';
import toptitle from '../images/toptitle.png';
import {useLoginFormState} from '../hooks/useLoginFormState';
import {useUserState} from '../hooks/useUserState';
import {openDocument} from '../utils/openDocument';
import {useLoadingState} from '../hooks/useLoadingState';
import {useNavigate, useSearchParams} from 'react-router-dom';

const LoggedinPage = () => {
  const navigate = useNavigate();
  const {setLoading} = useLoadingState();
  const [searchParams] = useSearchParams();
  const {postOauth2Token} = useLoginFormState();
  const {user, setUserData} = useUserState();

  useEffect(() => {
    postOauth2Token(searchParams.get('code'))
  }, []);

  useEffect(() => {
    if (user.ucl === '') {
      return;
    } else if (user.ucl === 'student') {
      navigate('/student/')
      return
    }
    navigate('/not_allow')
    return
  }, [user]);

  return (
    <div className="wrapper background-image-display">
    </div>
  );
};

export default LoggedinPage;
