import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import {getCookie} from '../utils/cookie';

export const messageSendFormState = atom({
  key: 'messageSendFormState',
  default: {
    ownerId: '',
    message: '',
    errorMessage: '',
  },
});

export const useMessageSendFormState = () => {
  const [messageSendForm, setMessageSendForm] = useRecoilState(messageSendFormState);

  const postMessageSendForm = useCallback(
    async (message = null) => {
      const url = process.env.REACT_APP_API_ENDPOINT + '/students/messages';
      const method = 'POST';
      const headers = {
        'Authorization': getCookie('Authorization'),
        'accesstoken': getCookie('Accesstoken')
      };

      const submitBody = {
        owner_id: messageSendForm.ownerId ?? null,
        body: message || messageSendForm.message,
      };

      const res = await fetch(url, {method, headers, body: JSON.stringify(submitBody)})
      const data = await res.json();

      if (!res.ok) {
        return false;
      }

      return data;
    },
    [messageSendForm]
  );

  const clearMessageSendForm = useCallback(
    () => {
      setMessageSendForm({
        ownerId: '',
        message: '',
        errorMessage: '',
      })
    },
    []
  );

  return {
    messageSendForm: messageSendForm,
    setMessageSendForm: setMessageSendForm,
    postMessageSendForm,
    clearMessageSendForm,
  };
};
