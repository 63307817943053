import React, {useEffect} from 'react';
import '../App.css';
import toptitle from '../images/toptitle.png';
import {useUserState} from '../hooks/useUserState';

const LoggedinPage = () => {
  const {user, logout} = useUserState();

  useEffect(() => {
    if (user.ucl === '') {
      return;
    }
    // logout()
  }, []);

  return (
    <div className="wrapper background-image-display">
      <div className='uk-width-1-1 uk-flex uk-flex-middle'>
        <div className='uk-container uk-animation-fade'>
          <img className='uk-align-center uk-width-small' src={toptitle} alt="title" width="300" />
          これは、児童用の機能です。
        </div>
      </div>
    </div>
  );
};

export default LoggedinPage;
