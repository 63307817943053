import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import {decodeJwt} from '../utils/jwt';
import {getCookie, setCookie, setCookieGrade} from '../utils/cookie';
import {useNavigate} from 'react-router-dom';
import { useSearchParams } from "react-router-dom";

const userStateDefault = {
  sub: '',
  email: '',
  ucl: ''
}

export const userState = atom({
  key: 'userState',
  default: userStateDefault,
});

export const useUserState = () => {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userState);

  const [searchParams] = useSearchParams();

  const setUserData = useCallback(
    () => {
      const id_token = searchParams.get('id_token')
      const access_token = searchParams.get('access_token')

      setCookie(id_token, access_token)
      setUserFromIdToken(id_token);

      return {
        id_token,
        access_token
      };
    },
    []
  );

  const setUserFromIdToken = useCallback(
    (idToken) => {
      const userData = decodeJwt(idToken);
      const ucl = userData['custom:google_role']

      setUser({
        sub: userData['sub'],
        email: userData['email'],
        ucl: ucl
      });
    },
    []
  );

  const isLoggedin= useCallback(
    () => {
      const idToken = getCookie('Authorization');
      const accessToken = getCookie('Accesstoken');

      if (!idToken || !accessToken) {
        return false
      }

      setUserFromIdToken(idToken);

      return true;
    },
    []
  );

  const getURI = () => {
    const port = window.location.port ? `:${window.location.port}` : ''
    return `${window.location.protocol}//${window.location.hostname}${port}`
  }

  const login = () => {
    window.location.replace(`${process.env.REACT_APP_COGNITO_ENDPOINT}/authorize?identity_provider=mamoreport&response_type=CODE&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&scope=openid&redirect_uri=${getURI()}/loggedin`)
  }

  const logout = (uri_path = "") => {
    setCookie('', '');
    setCookieGrade('');
    setUser(userStateDefault);
    window.location.replace(`${process.env.REACT_APP_COGNITO_ENDPOINT}/logout?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&logout_uri=${getURI() + uri_path}`)
  };

  return {
    user: user,
    setUser: setUser,
    setUserFromIdToken,
    login,
    logout,
    setUserData,
    isLoggedin,
  };
};
