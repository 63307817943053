import React, { useState, useEffect } from 'react';

export const WordImageButton = ({children, onClick, type}) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    if (onClick) {
      onClick()
    }
  };

  useEffect(() => {
    if (isClicked) {
      setInterval(() => {
        setIsClicked(false)
      }, 500);
    }    
  }, [isClicked]);

  return (
    <button
      className="uk-button uk-card uk-card-default uk-card-small selectCard"
      style={{
        borderColor: isClicked ? "blue" : "white",
        color: "white",
        transition: "border-color 0.1s ease-in-out",
        padding: '0px',
      }}
      onClick={handleClick}
      type={type}
    >
      {children}
    </button>
  );
}