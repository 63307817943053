import React from 'react';
import '../../App.css';
import toptitle from '../../images/toptitle.png';
import {useUserState} from '../../hooks/useUserState';
import {useNavigate} from 'react-router-dom';
import {UserInfo} from '../../components/modal/userInfo';

export const StudentNavigationBar = ({disabledToTop}) => {
  const navigate = useNavigate();
  const {logout} = useUserState();

  const handleToTop = (event) => {
    if (disabledToTop) {
      return;
    }
    navigate('/student')
  }

  const handleLogout = (event) => {
    logout()
  }

  return (
    <>
      <div className='fixed uk-width-1-1 uk-animation-fade navbar'>
        <nav className="uk-navbar-container uk-padding-small" uk-navbar="true">
          <div className="uk-navbar-center uk-width-5-6@xl uk-width-1-1@s" uk-grid="true">
            <div className="uk-width-1-3 uk-flex uk-flex-left uk-padding-remove-left">
              <ul className="uk-navbar-nav uk-flex uk-flex-middle">
                <li><a href="#" onClick={handleToTop}><img className="uk-margin-small-left uk-margin-right" src={toptitle} width="100px" alt="" /></a></li>
              </ul>
            </div>
            <div className="uk-width-1-3 uk-flex uk-flex-center uk-padding-remove-left">

            </div>
            <div className="uk-width-1-3 uk-flex uk-flex-right">
              <ul className="uk-navbar-nav">
                <li>
                  <a href="#" className="uk-margin-small-right"><span uk-icon="icon: user; ratio: 1.3"></span></a>
                  <div className="uk-navbar-dropdown">
                      <ul className="uk-nav uk-navbar-dropdown-nav">
                        <li><a href="#" uk-toggle="target: #modal-userInformation">ユーザ情報</a></li>
                        <li><a href="#" onClick={handleLogout}>ログアウト</a></li>
                      </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <UserInfo />
    </>
  );
};
