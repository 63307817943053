import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/login';
import LoggedinPage from './pages/loggedin';
import NotAllowPage from './pages/not_allow';
import StudentTopPage from './pages/student/index';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* TOP */}
        <Route path="/" exact element={<LoginPage />} />
        <Route path="/loggedin" exact element={<LoggedinPage />} />
        <Route path="/not_allow" exact element={<NotAllowPage />} />
        {/* student */}
        <Route path="/student/" exact element={<StudentTopPage />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
