import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import {getCookie} from '../utils/cookie';

export const messagesState = atom({
  key: 'messagesState',
  default: {
    currentPage: '1',
    searchSchool: '',
    searchStatus: '',
    data: []
  },
});

export const useMessagesState = () => {
  const [messages, setMessages] = useRecoilState(messagesState);

  const fetchMessages = useCallback(
    async (userId) => {
      const url = process.env.REACT_APP_API_ENDPOINT + '/students/messages';
      const method = 'GET';
      const headers = {
        'Authorization': getCookie('Authorization'),
        'accesstoken': getCookie('Accesstoken')
      };

      const res = await fetch(url, {method, headers});
      const data = await res.json();

      if (!res.ok) {
        return false;
      }

      setMessages({data: data['messages']})

      return data;
    },
    []
  );

  const deleteMessages = useCallback(
    async (messageId) => {
      const url = process.env.REACT_APP_API_ENDPOINT + '/students/messages';
      const method = 'DELETE';
      const headers = {
        'Authorization': getCookie('Authorization'),
        'accesstoken': getCookie('Accesstoken')
      };
      const body = {talk_id: messageId};

      const res = await fetch(url, {method, headers, body: JSON.stringify(body)});
      const data = await res.json();

      if (!res.ok) {
        return false;
      }

      return data;
    },
    []
  );

  return {
    messages: messages,
    setMessages: setMessages,
    fetchMessages,
    deleteMessages,
  };
};
