import React, {useEffect} from 'react';
import '../../App.css';
import {useCounselorStatusState} from '../../hooks/useCounselorStatusState';

export const Counselors = () => {
  const {counselorStatus, fetchCounselorStatus} = useCounselorStatusState();

  useEffect(() => {
    fetchCounselorStatus()

    const timer = setInterval(() => {
      fetchCounselorStatus()
    }, 10000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <div className="uk-padding-remove-top uk-margin-bottom">
        <ul className="uk-child-width-1-4@m uk-grid-small uk-grid-match" uk-grid="true">
          {counselorStatus.data.map((counselor) => {
            return (
              <li id="1" className="uk-width-1-4@m uk-margin-small-bottom uk-margin-small-top">
                <div className="uk-grid-small uk-flex-middle uk-text-left" uk-grid="true">
                  <div className="uk-width-auto">
                      <img className="uk-border-circle" width="60" height="60" src={counselor.image_url} alt="Avatar" />
                  </div>
                  <div className="uk-width-expand">
                    {Number(counselor.status) === 1 && (
                      <span className="uk-label uk-label-success">お話しできます</span>
                    )}
                    {Number(counselor.status) === 2 && (
                      <span className="uk-label uk-label-warning">応対中です</span>
                    )}
                    {Number(counselor.status) === 3 && (
                      <span className="uk-label label-muted">不在です</span>
                    )}
                    
                    <p className="conselorName">{counselor.name}</p>
                    <p className="uk-text-muted uk-margin-remove-top counselorSmallText">{counselor.hours}</p>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};
