import React from 'react';
import '../App.css';
import {useLoadingState} from '../hooks/useLoadingState';
import preloader from '../images/Preloader_1.gif';

export const Loading = () => {
  const {loading} = useLoadingState();

  return (
    <>
      {loading && (
        <div className={`loading ${loading ? '' : 'uk-animation-fade'} uk-text-center`}>
          <img src={preloader} /><br />
          <p className="uk-text-center">読み込み中です…</p>
        </div>
      )}
    </>
  );
};
