import stamp_1 from '../images/stamp_1.png';
import stamp_2 from '../images/stamp_2.png';
import stamp_3 from '../images/stamp_3.png';
import stamp_4 from '../images/stamp_4.png';
import stamp_5 from '../images/stamp_5.png';
import stamp_6 from '../images/stamp_6.png';
import stamp_7 from '../images/stamp_7.png';
import stamp_8 from '../images/stamp_8.png';
import stamp_9 from '../images/stamp_9.png';
import stamp_10 from '../images/stamp_10.png';
import stamp_11 from '../images/stamp_11.png';
import stamp_12 from '../images/stamp_12.png';
import stamp_13 from '../images/stamp_13.png';
import stamp_14 from '../images/stamp_14.png';
import stamp_15 from '../images/stamp_15.png';
import stamp_16 from '../images/stamp_16.png';
import stamp_17 from '../images/stamp_17.png';
import stamp_18 from '../images/stamp_18.png';

export const enums = {
  wordImages: [

    ['<ruby><rb>言葉</rb><rp>(</rp><rt>ことば</rt><rp>)</rp></ruby>の<ruby><rb>暴力</rb><rp>(</rp><rt>ぼうりょく</rt><rp>)</rp></ruby>', stamp_1, '○○さんから、言葉の暴力をうけています。'],
    ['なぐる・ける', stamp_2, '○○さんから、なぐられたり、けられたりしています。'],
    ['ネットで<ruby><rb>悪口</rb><rp>(</rp><rt>わるぐち</rt><rp>)</rp></ruby>', stamp_3, '○○さんから、ネットで悪口を言われています。'],
    ['<ruby><rb>仲間外</rb><rp>(</rp><rt>なかまはず</rt><rp>)</rp></ruby>れ', stamp_4, '○○さんから、仲間外れにされています。'],
    ['<ruby><rb>悪口</rb><rp>(</rp><rt>わるぐち</rt><rp>)</rp></ruby>を<ruby><rb>言</rb><rp>(</rp><rt>い</rt><rp>)</rp></ruby>われる', stamp_5, '○○さんが、私の悪口を言っています。'],
    ['うそを<ruby><rb>言</rb><rp>(</rp><rt>い</rt><rp>)</rp></ruby>いふらされる', stamp_6, '○○さんに、うそを言いふらされています。'],
    ['<ruby><rb>悲</rb><rp>(</rp><rt>かな</rt><rp>)</rp></ruby>しんでいる', stamp_7, '○○のことで、悲しんでいます。'],
    ['ルール<ruby><rb>違反</rb><rp>(</rp><rt>いはん</rt><rp>)</rp></ruby>', stamp_8, '○○さんが、○○をしています。'],
    ['ものを<ruby><rb>壊</rb><rp>(</rp><rt>こわ</rt><rp>)</rp></ruby>した', stamp_9, '○○さんが、○○を壊しました。'],
    ['ものがなくなった', stamp_10, '私の○○がなくなりました。'],
    ['<ruby><rb>先生</rb><rp>(</rp><rt>せんせい</rt><rp>)</rp></ruby>のこと', stamp_11, '先生のことで悩んでいます。'],
    ['<ruby><rb>家</rb><rp>(</rp><rt>いえ</rt><rp>)</rp></ruby>のこと', stamp_12, '家のことで悩んでいます。'],
    ['<ruby><rb>見守</rb><rp>(</rp><rt>みまも</rt><rp>)</rp></ruby>ってほしい', stamp_13, '見守っていてほしいです。'],
    ['<ruby><rb>解決</rb><rp>(</rp><rt>かいけつ</rt><rp>)</rp></ruby>してほしい', stamp_14, '解決してほしいです。'],
    ['<ruby><rb>相手</rb><rp>(</rp><rt>あいて</rt><rp>)</rp></ruby>に<ruby><rb>注意</rb><rp>(</rp><rt>ちゅうい</rt><rp>)</rp></ruby>してほしい', stamp_15, '相手に注意してほしいです。'],
    ['<ruby><rb>仲直</rb><rp>(</rp><rt>なかなお</rt><rp>)</rp></ruby>りしたい', stamp_16, '仲直りしたいです。'],
    ['ただ<ruby><rb>伝</rb><rp>(</rp><rt>つた</rt><rp>)</rp></ruby>えたいだけ', stamp_17, 'ただ伝えたいだけです。'],
    ['<ruby><rb>内緒</rb><rp>(</rp><rt>ないしょ</rt><rp>)</rp></ruby>にしていてほしい', stamp_18, '私が話したことは、ほかの人には内緒にしていてほしいです。'],
  ],
}
