import React from 'react';
import {RecoilRoot} from 'recoil';
import AppRoutes from './router';
import {Loading} from './components/loading';

const App = () => {
  return (
    <RecoilRoot>
      <Loading />
      <AppRoutes />
    </RecoilRoot>
  );
}

export default App;
