import React, {useEffect, useRef, useState} from 'react';
import '../../App.css';
import {useUserState} from '../../hooks/useUserState';
import {useNavigate} from 'react-router-dom';
import {StudentNavigationBar} from '../../components/navi/studentNavigationBar';
import {Counselors} from '../../components/navi/counselors';
import {useMessagesState} from '../../hooks/useMessagesState';
import {useMessageSendFormState} from '../../hooks/useMessageSendFormState';
import moment from 'moment-timezone';
import {enums} from '../../utils/enums';
import {WordImageButton} from '../../components/element/wordImageButton';

const StudentTopPage = () => {
  const navigate = useNavigate();
  const {user, isLoggedin} = useUserState();
  const {messages, fetchMessages, deleteMessages} = useMessagesState();
  const {messageSendForm, setMessageSendForm, postMessageSendForm, clearMessageSendForm} = useMessageSendFormState();
  const [lastMessageId, setLastMessageId] = useState(null);

  const scrollBottomRef = useRef(null);

  useEffect(() => {
    if (isLoggedin() === false) {
      navigate('/')
    }
  }, []);

  useEffect(() => {
    if (user.sub) {
      setMessageSendForm({
        ...messageSendForm,
        ownerId: user.sub
      });
      fetchMessages(user.sub)
    }

    const timer = setInterval(() => {
      if (user.sub) {
        fetchMessages(user.sub)
      }
    }, 10000);

    return () => {
      clearInterval(timer);
    };
  }, [user]);

  useEffect(() => {
    if (messages.data.length > 0) {
      const newLastMessageId = messages.data[messages.data.length - 1].id
      if (lastMessageId !== newLastMessageId) {
        setLastMessageId(newLastMessageId)
        scrollBottomRef.current.scrollIntoView(false);
      }
    }
  }, [messages]);

  const handleChange = (event) => {
    setMessageSendForm({
      ...messageSendForm,
      errorMessage: '',
      [event.target.name]: event.target.value
    });
  }

  const handleMessageDelete = async (messageId) => {
    if(window.confirm('本当に削除していいですか？') === false) {
      return
    }
    await deleteMessages(messageId)
    await fetchMessages(user.sub)
  }

  const handleCickImage = (word) => {
    setMessageSendForm({
      ...messageSendForm,
      errorMessage: '',
      message: messageSendForm.message + word
    });
  }

  const handleReload = () => {
    fetchMessages(user.sub)
  }

  const handleMessageSend = async () => {
    if (messageSendForm.message === '') {
      setMessageSendForm({
        ...messageSendForm,
        errorMessage: 'メッセージを入力してください。'
      });
      return;
    }

    const res = await postMessageSendForm();

    if (res === false) {
      setMessageSendForm({
        ...messageSendForm,
        errorMessage: '送信に失敗しました。'
      });
      return;
    }

    clearMessageSendForm();
    fetchMessages(user.sub);
  }

  const handleFinishMessageSend = async () => {
    const res = await postMessageSendForm('今日はおしまいにします。');

    if (res === false) {
      setMessageSendForm({
        ...messageSendForm,
        errorMessage: '送信に失敗しました。'
      });
      return;
    }

    clearMessageSendForm();
    fetchMessages(user.sub);
  }

  return (
    <div className="wrapper">
      <div className='uk-width-1-1 uk-flex uk-flex-middle'>
        <StudentNavigationBar />
        <div className="uk-width-5-6@xl uk-width-1-1@s uk-position-top uk-container uk-margin-large-top">
          <div className="uk-width-1-1 uk-text-center uk-align-center">
            <Counselors />
            <div id="studentMessageList" className="uk-panel uk-panel-scrollable uk-height-large">
              <ul className="uk-list uk-list-divider">
                {messages.data.length === 0 && (
                  <li key={`student-message-li-nomessage`} className="kaiwa imessage">
                    <p className="uk-text-small uk-text-bold uk-margin-small-left uk-margin-remove-bottom uk-text-left">相談員</p>
                    <div className="fukidasi left">
                      <p className="uk-margin-remove-top uk-margin-remove-bottom messageBox uk-text-left">気になることがあったら、なんでも聞いてください！</p>
                    </div>
                  </li>
                )}
                {messages.data.map((message, i) => (
                  <li key={`student-message-li-${i}`} className="kaiwa imessage">
                    <p className={message.talker_id === user.sub ? "uk-text-small uk-text-bold uk-margin-small-right uk-margin-remove-bottom uk-text-right" : "uk-text-small uk-text-bold uk-margin-small-left uk-margin-remove-bottom uk-text-left"}>
                      {message.talker_id === user.sub ? 'あなた' : message.talker_id === 'system user' ? "自動応答メッセージ" : '相談員'}

                    </p>
                    <div className={message.talker_id === user.sub ? "fukidasi right" : "fukidasi left"}>
                      <p className="uk-margin-remove-top uk-margin-remove-bottom messageBox uk-text-left">{message.body}</p>
                      <p className={message.talker_id === user.sub ? "uk-margin-small-top uk-margin-remove-bottom uk-text-small uk-text-right": "uk-margin-small-top uk-margin-remove-bottom uk-text-small uk-text-left"}>
                        {moment.utc(message.created_at).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm')}<br />
                        {message.talker_id === user.sub && (
                          <a className="uk-margin-small-left delete-link" href="#" onClick={() => handleMessageDelete(message.id)}>削除</a>
                        )}
                      </p>
                    </div>                    
                  </li>
                ))}
                {messages.data.length !== 0 && (<li key={`student-message-li-end`} ref={scrollBottomRef}><a href="#" onClick={handleReload}><ruby>最新<rp>(</rp><rt>さいしん</rt><rp>)</rp></ruby>のメッセージを<ruby>読<rp>(</rp><rt>よ</rt><rp>)</rp></ruby>み<ruby>込<rp>(</rp><rt>こ</rt><rp>)</rp></ruby>む</a></li>)}
              </ul>
            </div>
            <div className="uk-flex uk-flex-bottom">
              <div className="uk-inline uk-width-expand uk-margin-top uk-margin-right">
                <textarea id="talkChild" className="uk-textarea textarea_fixed" rows="3" placeholder="メッセージを書いてください" name="message" value={messageSendForm.message} onChange={handleChange}></textarea>
              </div>
              <div className="uk-inline uk-flex uk-margin-left-auto uk-margin-top">
                <button className="uk-button uk-button-primary" id="sendChild" onClick={handleMessageSend}>メッセージを送る</button>
              </div>
            </div>
            <div className="uk-width-expand uk-text-left" style={{marginTop: "10px"}}>
              <button className="uk-button uk-button-primary" id="clearMessage" onClick={clearMessageSendForm}>はじめから<ruby>書<rp>(</rp><rt>か</rt><rp>)</rp></ruby>き<ruby>直<rp>(</rp><rt>なお</rt><rp>)</rp></ruby>す</button>
              <button className="uk-button uk-button-primary" style={{marginLeft: "20px"}} onClick={handleFinishMessageSend}>今日はおしまいにする</button>
            </div>
            {messageSendForm.errorMessage && (
              <p className="uk-margin-remove uk-text-left uk-text-danger">{messageSendForm.errorMessage}</p>
            )}
            <article class="uk-comment uk-comment-primary uk-margin-top uk-margin-large-bottom">
              <p className="uk-text-left uk-margin-small-bottom">このボタンをつかって、<ruby>簡単<rp>(</rp><rt>かんたん</rt><rp>)</rp></ruby>にメッセージをつくれます。<br /><ruby>伝<rp>(</rp><rt>つた</rt><rp>)</rp></ruby>えたいことを<ruby>全部<rp>(</rp><rt>ぜんぶ</rt><rp>)</rp></ruby><ruby>選<rp>(</rp><rt>えら</rt><rp>)</rp></ruby>んだあと、○○の<ruby>部分<rp>(</rp><rt>ぶぶん</rt><rp>)</rp></ruby>を<ruby>書<rp>(</rp><rt>か</rt><rp>)</rp></ruby>き<ruby>換<rp>(</rp><rt>か</rt><rp>)</rp></ruby>えてメッセージを<ruby>送<rp>(</rp><rt>おく</rt><rp>)</rp></ruby>ってください。</p>
              <ul className="uk-child-width-1-6@l uk-margin-small-top uk-grid-small uk-grid-match" uk-grid="true">
                {enums.wordImages.map((wordImage, i) => (
                  <li id={`target_${i}`}  className="uk-width-1-6@l uk-width-1-4@s uk-margin-small-bottom uk-margin-small-top">
                    <WordImageButton onClick={() => handleCickImage(wordImage[2])} type="button">
                      <div className="uk-card-body uk-padding-remove uk-text-center" style={{padding: '10px'}}>
                        <div className="uk-flex-middle" uk-grid="true">
                          <div className="uk-width-1-4@xl uk-width-1-3@s uk-card-media-left">
                            <img className="selectImage" src={wordImage[1]} />
                          </div>
                          <div className="uk-width-expand uk-padding-remove uk-text-left">
                            <h6 style={{fontSize: '0.75rem'}} className="cardtext" ><div dangerouslySetInnerHTML={{__html: wordImage[0]}} /></h6>
                          </div>
                        </div>
                      </div>
                    </WordImageButton>
                  </li>
                ))}
              </ul>
            </article>

          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentTopPage;
